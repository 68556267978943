import { Pipe, PipeTransform } from '@angular/core';
import { pwToFixed } from '@pwiz/infra/ts';

@Pipe({
  name: 'pwFixed',
  standalone: true,
})
export class FixedPipe implements PipeTransform {
  transform(
    value: number | null | undefined,
    fractionDigits = 2,
  ): string | null {
    if (!value) {
      return value == null ? null : '0';
    }
    return pwToFixed(value, fractionDigits);
  }
}
